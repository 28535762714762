import React from 'react';
import './MainPage.css';

// Existing hosts data
const hosts = [
  {
    name: "Will Byrd",
    month: "October",
    bio: "Hosting in October",
    image: "/October.png",
    hoverImage: "/October2.png"
  },
  {
    name: "Andrew Rynning",
    month: "November",
    bio: "Hosting in November",
    image: "November.png",
    hoverImage: "/November2.png"
  },
  {
    name: "Dan Gantman",
    month: "December",
    bio: "Hosting in December",
    image: "/December.png",
    hoverImage: "/December2.png"
  },
];

function HostCard({ host, isCurrent }) {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <div
      className={`host-card ${isCurrent ? 'ring-4 ring-[#FFD65E]' : ''}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="host-card-image-container">
        <img
          src={isHovered ? host.hoverImage : host.image}
          alt={host.name}
          className={isHovered ? 'hover-image' : ''}
        />
      </div>
      <div className="host-card-content">
        <h3 className="host-card-name">{host.name}</h3>
        <p className="host-card-month">{host.month}</p>
        <p className="host-card-bio">{host.bio}</p>
      </div>
    </div>
  );
}

function MainPage() {
  return (
    <div className="container">
      <section className="text-center mb-8">
        <h1 className="heading">Silly Street Open Mic</h1>
      </section>

      <div className="mic-info-container">
        <h2 className="mic-info-header">OPEN MIC INFORMATION</h2>
        <div className="mic-info-grid">
          <div className="mic-info-box">
            <h3>Venue:</h3>
            <p>Gib's</p>
          </div>
          <div className="mic-info-box">
            <h3>Address:</h3>
            <p>1380 Williamson St, Madison, WI 53703</p>
          </div>
          <div className="mic-info-box">
            <h3>Date and Time:</h3>
            <p>Every Monday at 8 PM, List at 7:30 PM</p>
          </div>
        </div>
        <div className="mic-info-footer">
          <p>The Silly Street Open Mic is a cooperative effort of the local comedians of Madison. Each month, a new comedian
          takes the reigns, hosting the mic and adding their own unique flair and intrigue to the show. Stop by your first open mic to join the fun with your own
          co-op card, that you can redeem for free tickets, drinks, and pizza.</p>
        </div>
      </div>

      <section className="mb-8">
        <h2 className="heading">Upcoming Hosts</h2>
        <div className="host-cards">
          {hosts.map((host, index) => (
            <HostCard key={host.name} host={host} isCurrent={index === 0} />
          ))}
        </div>
      </section>
    </div>
  );
}

export default MainPage;
